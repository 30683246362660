<template>

<Animation3 />

 

  <div id="JobApply" class="page">
        <!-- <Animation3 /> -->
    <div class="flex margin container   my-30 margin container">
      <p>{{ err?.message }}</p>
    </div> 
  
    <div class="top-flex margin container" v-if="job_data?.data">

      
 
      <h1 class="page-title py-4 banner">{{content.JobTitleText}}: {{ job_data.data.title }}  </h1>
      
      <span v-if="job_data.data.office!=null">
      <p class="paragraph-text">
        
        {{ job_data.data.office.name }},
        {{ job_data.data.office.address }},
        {{ job_data.data.office.city }},
        {{ job_data.data.office.country }}
      </p>
      </span>


          <span v-else>
      <p class="paragraph-text">
        {{content.EmptyPropertyMsg}}
      </p>
      </span>


    </div>

    <br />
    <br />
    <br />

    <div class="container" v-if="job_data.data.requirements">
      <h1 class="page-title">{{content.IdealCandidateHeading}}</h1>
       <ul class="customlist" v-html="job_data?.data.requirements"  > </ul>
    </div>

    <br />
    <br />
    <br />

 


    <div class="container" v-if="job_data.data.responsibilities">
      <h1 class="page-title">{{content.ResponsibilitesHeading}}</h1>
      <ul class="customlist"  v-html="job_data.data.responsibilities" >   </ul>
      <br />
      <br />
      <br />
    </div>
    <br /><br /><br />

    <div class="container input-form" v-if="job_data.data">
      <p class="form-heading">{{content.ApplyFormHeading}}</p>
      <p class="sub-heading">{{content.ApplyFormSubHeading}}</p>
      <p class="hr-line"></p>
        <br />   <br /> 


    

        <form
          @submit.prevent="submitForm"
          method="post"
          enctype="multipart/form-data"
          ref="anyName"
        >
          <div class="grid-container">
            
           
               

                   <Input
                  name="firstname"
                  id="firstname"
                  v-model="firstname"
                  type="text"
                   required="true"
                  fieldLabel="First Name"
                  :placeholder="content.JobApplyFormFieldsPlaceHolders.FirstName"
                />
 
            
              

          

                  <Input
                  name="lastname"
                  id="lastname"
                  v-model="lastname"
                  type="text"
                   required="true"
                  fieldLabel="Last Name"
                   :placeholder="content.JobApplyFormFieldsPlaceHolders.LastName"
                />


         
      

           
                        <Input
                  name="email"
                  id="email"
                  v-model="email"
                  type="text"
                   required="true"
                  fieldLabel="Email"
                   :placeholder="content.JobApplyFormFieldsPlaceHolders.Email"
                   :invalidInputMessage="content.JobApplyForm.InvalidEmail"
                />

               
               
               
              
       
               
              
                
                <Input

                  name="phone_number"
                  id="phone_number"
                  v-model="phone_number"
                  type="text"
                  required="true"
                  fieldLabel="Phone Number"
                  :placeholder="content.JobApplyFormFieldsPlaceHolders.Phone"
                  :invalidInputMessage="content.JobApplyForm.InvalidPhone"

                />
                
            
          

           
                <Input
                  name="linkedUrl"
                  id="linkedUrl"
                  v-model="linkedUrl"
                  type="text"
                    required="true"
                
                  fieldLabel="Linked Url"
                  :placeholder="content.JobApplyFormFieldsPlaceHolders.LinkedInUrl"
                   :invalidInputMessage="content.JobApplyForm.InvalidWebsiteUrl"
                />
        
             
                <Input
                  name="website"
                  id="website"
                  v-model="website_url"
                  type="text"
                  required="false"
                  fieldLabel="Portfolio or Website Url"
                   :placeholder="content.JobApplyFormFieldsPlaceHolders.WebsiteUrl"
                   :invalidInputMessage="content.JobApplyForm.InvalidWebsiteUrl"
                />
          

            
                
                   <Input
                  name="availability"
                  id="availability"
                  v-model="availability"
                  type="date"
               
                    required="false"
                  fieldLabel="availability"
                  :placeholder="content.JobApplyFormFieldsPlaceHolders.Availibility"
                />
                

                
      
                <Input
                  name="heared_about"
                  id="heared_about"
                  v-model="heared_about"
                  type="text"
                 required="false"
                  fieldLabel="Heard About"
                      :placeholder="content.JobApplyFormFieldsPlaceHolders.HeardAbout"
                />
     
            
               
                                

                  
                
                  <div class="input-wrapper">
                     <label    style="margin-top: -39px; position: absolute;">Cv/Resume <span style="color:red"> (*)</span></label>
                    <span class="fieldset-bg"
                      ><span class="fieldset-bg__alert"
                        ><svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="email-error"
                          class=""
                        >
                          <path
                            d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
                            fill="#FFF"
                          ></path></svg></span
                    ></span>

                    <input
                      type="file"
                      id="cv"
                      name="cv"
                      ref="file"
                      accept=".pdf"
                  
                    />
                  </div>
              


             


 
            
           
                
                                

                   
                  <div class="input-wrapper">
                       <label    style="margin-top: -39px; position: absolute;">Cover Letter (Optional)</label>
                    <span class="fieldset-bg"
                      ><span class="fieldset-bg__alert"
                        ><svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="email-error"
                          class=""
                        >
                          <path
                            d="M4 8l1.5 1.5L4 11 2.5 9.5 4 8zm1-7v6H3V1h2z"
                            fill="#FFF"
                          ></path></svg></span
                    ></span>

                    <input
                      type="file"
                      id="cover_letter"
                      name="cover_letter"
                      ref="file"
                      accept=".pdf"
                      
                    />
                  </div>
           
 
            <div class="flex justify-center ">
               
              <Input
                name="company"
                id="company"
                v-model="company"
                type="text"
                class="w-full"
                fieldLabel="Company"
                  required="false"
                 :placeholder="content.JobApplyFormFieldsPlaceHolders.CurrentCompany"
              />
            </div>
          </div>
          <br />

          <div class="bottom-section flex justify-end mx-22">
            <span style="">
            <Button
              class="submit-btn"
              :name="content.FormSubmitButtonText"
              type="submit"
              :disable="disable"
            />
            </span>
          </div>
          <div>
            <!-- <h3>Data retrieved from server:</h3> -->
            <!-- <p v-if="success">{{ success }}</p>
          <pre>{{ response }}</pre> -->
          </div>
        </form>
     


    </div>
    <br />
    <br />
    <br />
  </div>


 

</template>

<script>

import constant from "../config/constants";    //find all the api urls, assets, images path here



import Input from "@/components/Input";
import Animation3 from "@/components/Animation3";

 

import Button from "@/components/Button";
import axios from "axios";
import { notification } from "ant-design-vue";

import { LanguageStrings } from "@/languages";
 



export default {

  name: "Apply",
  
  components: {
    Input,
    Button,
 
    Animation3
  },
  data() {
 

    return {

      job_id: "",
      job_data: null,
      firstname: "",
      lastname: "",

      email: "",
      phone_number: "",

      company: "",
   

      website_url: "",
      cv: "",

      linkedUrl: "",
      cover_letter: "",

      availability: "",
      heared_about: "",

      error: "",
      err: "",
      response: "",
      success: "",
      activeClass: "active",
      disable: false,

      content: null
 


    };
  },

    methods: {


     
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    
    validPhone: function (phone_number){
      var ph= /^(00|\+)[1-9]{1}([0-9][\s]*){9,16}$/im;
       return ph.test(phone_number);

    },

    

   openNotificationWithIcon(type) {
      notification[type]({
        message: `${type}`,
        description:
          type === "error"
            ? this.errors
            : this.content.JobApplyForm.SuccessMessage,
      });
      setTimeout(() => {
        this.disable = false;
      }, 6000);
    },
    setValue(event) {
      this.event.target.name = event.target.value;
    },
    submitForm(e) {

      e.preventDefault();
         this.disable = true;

      setTimeout(() => {
        this.disable = false;
      }, 7000);


      this.error = "";

      const formData = new FormData();
      var imagefile = document.querySelector("#cv");
      var imagefile2 = document.querySelector("#cover_letter");
      formData.append("cv", imagefile.files[0]);
      formData.append("cover_letter", imagefile2.files[0]);

      this.cv=imagefile.files[0];
      this.cover_letter=imagefile2.files[0];

      // formData.append("cv", imagefile.files[0]);
      // formData.append("cover_letter", imagefile.files[0]);
      formData.append("first_name", this.firstname);
      formData.append("last_name", this.lastname);
      formData.append("email", this.email);
      formData.append("phone", this.phone_number);
      formData.append("job_id", this.job_id);
      formData.append("availability", this.availability);

      formData.append("linkedUrl", this.linkedUrl);
      formData.append("website_url", this.website_url);
      formData.append("heared_about", this.heared_about);

     console.log(this.validPhone(this.phone_number), this.phone_number)

        if(
        this.firstname === "" ||
        this.lastname === "" ||
        this.email === "" ||
         this.phone_number === "" ||
          this.linkedUrl === "" ||
         this.cv == "undefined"  
         
         
      ) 
      {
       
        this.errors = this.content.JobApplyForm.AllFieldsRequired;
        this.openNotificationWithIcon("error");
      }
      else if(!this.validEmail(this.email)) {
        this.errors =  this.content.JobApplyForm.InvalidEmail;
        this.openNotificationWithIcon("error");
      }
      else if(this.validPhone(this.phone_number)==false) {
        this.errors = this.content.JobApplyForm.InvalidPhone; 
        this.openNotificationWithIcon("error");
      }
 
      else{
      axios
        .post(constant.API_URL+"job-apply", formData, {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((response) => {
          if (response.data.status_code === 200) {

            this.message = response.data.message;
            this.openNotificationWithIcon("success");
          this.firstname="",
          this.lastname="",
          this.phone_number="",
          this.email="",
          this.heared_about="",
          this.linkedUrl="",
          this.website_url="",
          this.cv="",
          this.cover_letter="",
          this.company="",
          this.availability=""

         this.$refs.anyName.reset();


          }
          else if(response.data.status_code === 404){
               
            this.errors =  this.content.JobApplyForm.CvError; 
            this.openNotificationWithIcon("error");
               this.disable = true;
          } 
          
          else {
            this.errors =  this.content.JobApplyForm.ServerError;
            this.openNotificationWithIcon("error");
          }

           this.disable = true;


        


        })
        .catch((err) => {
          console.log("catach error body", err)
          this.errors = err.response;
           this.errors =  this.content.JobApplyForm.ServerError;
          this.openNotificationWithIcon("error");
        });



      }


 

      
  



    },
  },
 


   created() {
    

     localStorage.getItem("siteLanguage") === "GR" 
      ? (this.content = LanguageStrings.gr.JobsApplyPage)
      : (this.content = LanguageStrings.en.JobsApplyPage);
   
      document.querySelector("head title").innerText = this.content.MetaTitle;
    axios
      .get(
        constant.API_URL+`single-job/${this.$route.params.slug}`
      )
      .then(response=> {
       
        this.job_id= response.data.data.id,
        this.job_data = response.data
        
        })
        
     
     
      .catch((err) => (this.err = err));




  },

   mounted() {
         window.scrollTo(0, 0);
  },




 
};
</script>

